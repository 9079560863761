import "../styles/globals.css";
import { useState, useEffect } from "react";
import AppContext from "../helper/context";

export default function App({ Component, pageProps }) {
  const [sideBar, setSideBar] = useState(false);
  const [cryptos, setCryptos] = useState([]);

  useEffect(() => {
    fetch("/api/cryptos")
      .then((response) => response.json())
      .then((responseData) => {
        setCryptos(responseData);
      })
      .catch((error) => console.warn(error));
  }, []);

  return (
    <AppContext.Provider
      value={{
        sideBar: sideBar,
        setSideBar: setSideBar,
        cryptos: cryptos,
      }}
    >
      <Component {...pageProps} />
    </AppContext.Provider>
  );
}
