import React from "react";
interface AppContextInterface {
  sideBar: boolean;
  setSideBar: (show: boolean) => void;
  cryptos: [];
  setCryptos: any;
}

const AppContext = React.createContext<AppContextInterface | null>(null);

export default AppContext;
